const translationEn = {
  menu: ["About me", "Projects", "Contact", "DE"],
  about: {
    title: "About me",
    intro:
      "Full Stack Software Developer with 7+ years of hands-on experience, specializing in building scalable web applications with modern technologies.",
    info: {
      title: "Get to know me!",
      description: [
        "As a dedicated <strong>Full Stack Developer</strong>, I bring extensive experience in both frontend and backend development, with a strong focus on creating robust, user-centric applications. My expertise spans multiple technologies including React, Django, Next.js, and Node.js, allowing me to deliver comprehensive solutions from concept to deployment.",

        "My journey in software development began in 2011, starting with developing and maintaining web portals and internal software solutions. Over the years, I've progressed through various roles in software development, including frontend development positions at German companies where I worked on complex web applications. This diverse experience has given me deep insights into enterprise-level software development. I hold a Diploma in Full Stack Software Development from Code Institute (accredited by University of West of Scotland), where I earned distinctions and merits across multiple portfolio projects.",

        "I'm fluent in English (C1), German (B1), Italian, and Romanian (native), enabling effective communication in diverse team environments. As an active member of the tech community, including serving as a Slack Community Leader, I constantly stay updated with the latest industry trends and best practices. <strong>I am currently open to new opportunities</strong> where I can leverage my full stack expertise to create impactful solutions and contribute to innovative projects.",
      ],
    },
    certifications: "Latest Certifications & Achievements",
    skills: "Technical Skills",
    softSkills: "Professional Skills",
    softSkillsList: [
      "AI-Driven Development - Proficient in leveraging AI tools to enhance development workflow, reduce time spend for research, and productivity boost",
      "Project Management - Experience in planning and executing complex projects from conception to deployment",
      "Problem Solving - Strong analytical and debugging skills demonstrated across various technical challenges",
      "Team Collaboration - Experience in remote work environments with distributed teams",
      "Communication - Multilingual capabilities enabling effective communication in diverse environments",
      "Time Management - Proven ability to manage multiple projects and meet deadlines",
      "Agile Methodologies - Experience with Agile development practices and tools like Jira and Confluence",
      "Attention to Detail - Demonstrated through comprehensive testing and documentation",
      "User-Focused Design - Strong understanding of user needs and experience optimization",
      "Continuous Learning - Active community member and constant skills development",
    ],
    contact: "CONTACT",
  },
  projects: {
    title: "Projects",
    intro:
      "Welcome to my portfolio showcase. Here you'll find a selection of full-stack applications that demonstrate my expertise across various technologies and frameworks. Each project represents different challenges and solutions in web development, from e-commerce platforms to community-driven applications.",
    projectsList: {
      greenVeggies: [
        "A Django-based e-commerce platform designed to facilitate the online ordering and delivery of fresh, locally-sourced fruits and vegetables in Westerstede, Germany.",
        "This comprehensive platform features user authentication, product catalog management, advanced search and filtering capabilities, shopping cart functionality, and secure checkout with Stripe integration.",
        "The application includes specialized dashboards for staff and couriers, enabling efficient order management and delivery logistics. Staff members can track orders, update statuses, and manage user details, while couriers have access to delivery management tools with Google Maps integration.",
        "Built with Python/Django, PostgreSQL, Bootstrap, and various modern web technologies, the platform demonstrates a commitment to best practices in web development, including responsive design, CRUD operations, and comprehensive testing.",
      ],
      localListing: [
        "A comprehensive full-stack web application built with React and Django REST Framework, designed to facilitate local community marketplace interactions. The platform enables users to create, manage, and browse local listings while providing robust messaging and user management features.",
        "Frontend: Built with React 18 and modern web technologies, featuring real-time messaging, advanced search and filtering capabilities, image handling with Cloudinary integration, and a responsive mobile-first design using Tailwind CSS. Implements secure user authentication, favorites management, and social sharing features.",
        "Backend: Powered by Django REST Framework, providing a robust API infrastructure with PostgreSQL database integration. Features include JWT authentication, comprehensive user management, listings CRUD operations, messaging system, reviews, and ratings. Implements Cloudinary for image storage and SendGrid for transactional emails.",
        "Notable features include a robust messaging system, user reviews and ratings, category-based listing organization, advanced search and filtering capabilities, and comprehensive user profile management. The application follows best practices in both frontend and backend development, with thorough testing and documentation.",
      ],
      inventory: [
        "A Python-based Inventory Management System designed to simplify the process of managing inventory items through a command-line interface.",
        "Built with Python and integrated with Google Sheets API for data storage, this application offers comprehensive inventory management capabilities including adding, updating, deleting, and searching for items.",
        "Features include robust input validation, detailed help documentation, and a user-friendly interface enhanced with the Rich library for better terminal output formatting.",
        "The system implements CRUD operations with Google Sheets integration, proper error handling, and comprehensive testing to ensure reliability and data integrity.",
      ],
      watchStore: [
        "Watch Store is a comprehensive e-commerce application that I have developed using React for the front-end and Node.js for the back-end. The application's user interface was designed using Tailwind CSS and the database management system is powered by MySQL",
      ],
      coldAvira: [
        "Cold Avira is a single-page application landing page that I have developed using ReactJS for the front-end framework, Tailwind CSS for styling, and SCSS for additional styling capabilities. ",
        "The design inspiration and some of the images used in this project have been sourced from templatemaster.com",
      ],
      foodOnline: [
        "Food Online is a web application that I have developed using Next.js and TypeScript. The front-end is designed to make it easy to place online orders, while the back-end provides robust functionality for managing order status, delivery, and other key features.",
      ],
      food: [
        "Master Cook is a web application that I have developed using ReactJs for the front-end and ExpressJs for the back-end. The user interface is designed to make it easy to place online orders, while the back-end provides robust functionality for managing order status, delivery, and other key features.",
      ],
      snippets: [
        "Snippets is a web application designed to store and share code snippets.",
        "This project is in continuous development. New features and changes are made almost daily. Small screen style may be removed entirely due to the nature of this application.",
        "I have a big ambition to develop this application and bring it to a higher level. New features like a bug tracking section following current trends and integration with ChatGPT are coming soon.",
        "Built with ReactJs for the front-end, Tailwind CSS for styling, ExpressJs for the back-end and MySQL for the database management.",
      ],
      movie: [
        "This web application, which is based on the MovieDB API, is built using VueJS and allows users to easily search the MovieDB database for movie information.",
        "In addition to utilizing Vue and its powerful state management, I have also employed Tailwind CSS for styling in this web application.",
      ],
      utility: [
        "This project serves as a demonstration of my proficiency in JavaScript and my ability to manipulate the Document Object Model (DOM).",
        "As a brief note, I would like to highlight my expertise in smart local storage, data formatting, and validation capabilities using vanilla JavaScript. For more detailed information, please refer to the information section on the live project.",
      ],
      design: [
        "This project features a clean and polished design that can be easily transformed into a successful website.",
        "This project has a mobile-first design approach, utilizing HTML and CSS to ensure optimal viewing experience on mobile devices.",
      ],
      bunbleClone: ["A Landing Page clone of one of the coolest codecanyon.net projects.", "Mobile-first design using HTML and CSS."],
      github: "Additional projects and my passion for coding can be found on my GitHub profile for further reference.",
    },
  },
  foodOnline: {
    description: ["Main features"],
    frontEnd: [
      "Customers can register and log in for easy orders.",
      "Orders can also be placed without the need for an account.",
      "Registered users can update their account details, view order statuses and history.",
    ],
    backEnd: [
      "Application administrators can easily create and update products, images, and categories.",
      "Application administrators can add new users and define their role as customer, administrator, or courier.",
      "Orders are processed and sent for delivery to the couriers' accounts.",
      "Order statuses can be changed and tracked.",
      "Administrators can view all courier orders by status, order details, and cash income.",
      "A day filter is also available, as well as a daily total income.",
    ],
    courier: [
      "The couriers section is designed for mobile devices and enables couriers to track orders assigned for delivery, orders delivered, and total income.",
      "Couriers can also check their delivered orders.",
      "Each order section has a 'Go to address' functionality that automatically opens Google Maps with the customer's address pre-filled.",
    ],
  },
  masterCook: {
    description: ["Main features"],
    frontEnd: [
      "Customers can register and log in for easy orders.",
      "Orders can be placed also without an account.",
      "Registered users can update their account details and view orders status and history.",
    ],
    backEnd: [
      "Application administrators can easily create and update products, images, and categories.",
      "Application administrators can add new users defining their role as a customer, administrator, or courier.",
      "Orders are processed and sent for delivery to the courier's accounts.",
      "Order status can be changed and tracked.",
      "Administrators can check every courier order by status, order details, and cash income.",
      "A day filter is also available and a daily total income.",
    ],
    courier: [
      "Couriers section is designed for mobile devices and track orders assigned for delivery, orders delivered, and total income.",
      "Couriers can also check their delivered orders.",
      'Every order section has a "Go to address"  functionality that automatically opens Google Maps with customer address prefilled.',
    ],
    demo: ["Demo accounts: ", "Administrator", "User", "Password", "Courier", "Customer"],
  },
  watchStore: {
    description: ["Application features:"],
    customersDescription: ["Customers:"],
    customers: [
      "can register and place a new order",
      "track order status",
      "update personal information and credentials (email and password update)",
    ],
    administratorDescription: ["Administrator:"],
    administrator: [
      "update personal information and credentials (email and password update)",
      "check and update new orders, check completed orders",
      "create, update and delete new products",
    ],
  },
  contact: {
    title: "Contact",
    intro:
      "I'm interested in both freelance opportunities and full-time positions, especially in roles that challenge me to learn and grow. If you have a project that needs coding expertise or a job opportunity that matches my skills, I'd love to hear from you.",
    subtitle: "Get in touch",
    description: "Feel free to reach out through any of the following channels.",
    address: "26655 Westerstede, Germany",
    phone: "(+49) 0151 7164 1545",
  },
  contactForm: {
    connectWith: "Connect",
    or: "Or",
    name: "Full Name",
    email: "Email Address",
    message: "How can I help you?",
    submit: "Send Message",
    soon: "Thank you for reaching out! I'll get back to you within 24 hours.",
    sending: "Sending...",
    errors: {
      validation: "Please ensure all fields are filled out correctly.",
      request: "Unable to send your message. Please try again later.",
      network: "Unable to send message. Please check your internet connection.",
      configuration: "There's an issue with the contact form configuration. Please try again later or contact me directly via email.",
      authentication: "Unable to send message due to security verification. Please try again later or contact me directly via email.",
    },
  },
};

export default translationEn;
