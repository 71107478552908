const translationDe = {
  menu: ["Über Mich", "Projekte", "Kontakt", "EN"],
  about: {
    title: "Über mich",
    intro:
      "Full-Stack-Softwareentwickler mit mehr als 7 Jahren praktischer Erfahrung, spezialisiert auf die Entwicklung skalierbarer Webanwendungen mit modernen Technologien.",
    info: {
      title: "Lernen Sie mich kennen!",
      description: [
        "Als engagierter <strong>Full-Stack-Entwickler</strong> bringe ich umfangreiche Erfahrung in der Frontend- und Backend-Entwicklung mit, mit starkem Fokus auf der Entwicklung robuster, nutzerorientierter Anwendungen. Meine Expertise umfasst verschiedene Technologien wie React, Django, Next.js und Node.js, was mir ermöglicht, umfassende Lösungen von der Konzeption bis zur Bereitstellung zu liefern.",

        "Meine Reise in der Softwareentwicklung begann 2011 mit der Entwicklung und Wartung von Webportalen und internen Softwarelösungen. Im Laufe der Jahre habe ich mich durch verschiedene Positionen in der Softwareentwicklung weiterentwickelt, einschließlich Frontend-Entwicklungspositionen bei deutschen Unternehmen, wo ich an komplexen Webanwendungen gearbeitet habe. Diese vielfältige Erfahrung hat mir tiefe Einblicke in die Softwareentwicklung auf Unternehmensebene verschafft. Ich besitze ein Diplom in Full-Stack-Softwareentwicklung vom Code Institute (akkreditiert von der University of West of Scotland), wo ich in mehreren Portfolio-Projekten 'Distinction' und 'Merit' erhalten habe.",

        "Ich spreche fließend Englisch (C1), Deutsch (B1), Italienisch und Rumänisch (Muttersprache), was eine effektive Kommunikation in verschiedenen Team-Umgebungen ermöglicht. Als aktives Mitglied der Tech-Community, einschließlich meiner Rolle als Slack Community Leader, bleibe ich stets auf dem Laufenden über die neuesten Branchentrends und Best Practices. <strong>Ich bin derzeit offen für neue Möglichkeiten</strong>, bei denen ich meine Full-Stack-Expertise einbringen und zu innovativen Projekten beitragen kann.",
      ],
    },
    certifications: "Aktuelle Zertifizierungen & Errungenschaften",
    skills: "Technische Fähigkeiten",
    softSkills: "Professionelle Fähigkeiten",
    softSkillsList: [
      "KI-Gestützte Entwicklung - Kompetent in der Nutzung von KI-Tools zur Verbesserung des Entwicklungsworkflows, Reduzierung der Recherchezeit und Steigerung der Produktivität",
      "Projektmanagement - Erfahrung in der Planung und Ausführung komplexer Projekte von der Konzeption bis zur Bereitstellung",
      "Problemlösung - Starke analytische und Debugging-Fähigkeiten, nachgewiesen in verschiedenen technischen Herausforderungen",
      "Teamzusammenarbeit - Erfahrung in Remote-Arbeitsumgebungen mit verteilten Teams",
      "Kommunikation - Mehrsprachige Fähigkeiten ermöglichen effektive Kommunikation in verschiedenen Umgebungen",
      "Zeitmanagement - Nachgewiesene Fähigkeit, mehrere Projekte zu verwalten und Termine einzuhalten",
      "Agile Methoden - Erfahrung mit agilen Entwicklungspraktiken und Tools wie Jira und Confluence",
      "Detailgenauigkeit - Nachgewiesen durch umfassendes Testing und Dokumentation",
      "Nutzerorientiertes Design - Ausgeprägtes Verständnis für Benutzerbedürfnisse und Erfahrungsoptimierung",
      "Kontinuierliches Lernen - Aktives Community-Mitglied und ständige Weiterentwicklung der Fähigkeiten",
    ],
    contact: "KONTAKT",
  },
  projects: {
    title: "Projekte",
    intro:
      "Willkommen in meiner Portfolio-Präsentation. Hier finden Sie eine Auswahl von Full-Stack-Anwendungen, die meine Expertise in verschiedenen Technologien und Frameworks demonstrieren. Jedes Projekt steht für unterschiedliche Herausforderungen und Lösungen in der Webentwicklung, von E-Commerce-Plattformen bis hin zu Community-gesteuerten Anwendungen.",
    projectsList: {
      greenVeggies: [
        "Eine Django-basierte E-Commerce-Plattform, die entwickelt wurde, um die Online-Bestellung und Lieferung von frischem, lokalem Obst und Gemüse in Westerstede, Deutschland, zu ermöglichen.",
        "Diese umfassende Plattform bietet Benutzerauthentifizierung, Produktkatalog-Management, erweiterte Such- und Filtermöglichkeiten, Warenkorb-Funktionalität und sichere Bezahlung mit Stripe-Integration.",
        "Die Anwendung enthält spezialisierte Dashboards für Mitarbeiter und Kuriere, die ein effizientes Bestellmanagement und eine effiziente Lieferlogistik ermöglichen. Mitarbeiter können Bestellungen verfolgen, Status aktualisieren und Benutzerdetails verwalten, während Kuriere Zugang zu Liefermanagement-Tools mit Google Maps-Integration haben.",
        "Entwickelt mit Python/Django, PostgreSQL, Bootstrap und verschiedenen modernen Webtechnologien, demonstriert die Plattform ein Engagement für Best Practices in der Webentwicklung, einschließlich responsivem Design, CRUD-Operationen und umfassendem Testing.",
      ],
      localListing: [
        "Eine umfassende Full-Stack-Webanwendung, entwickelt mit React und Django REST Framework, konzipiert zur Förderung lokaler Community-Marktplatz-Interaktionen. Die Plattform ermöglicht Benutzern das Erstellen, Verwalten und Durchsuchen lokaler Anzeigen und bietet robuste Nachrichten- und Benutzerverwaltungsfunktionen.",
        "Frontend: Entwickelt mit React 18 und modernen Webtechnologien, mit Echtzeit-Messaging, erweiterten Such- und Filterfunktionen, Bildverwaltung mit Cloudinary-Integration und einem responsiven Mobile-First-Design mit Tailwind CSS. Implementiert sichere Benutzerauthentifizierung, Favoritenverwaltung und Social-Sharing-Funktionen.",
        "Backend: Basiert auf Django REST Framework und bietet eine robuste API-Infrastruktur mit PostgreSQL-Datenbankintegration. Zu den Funktionen gehören JWT-Authentifizierung, umfassende Benutzerverwaltung, Anzeigen-CRUD-Operationen, Nachrichtensystem, Bewertungen und Ratings. Implementiert Cloudinary für die Bildspeicherung und SendGrid für Transaktions-E-Mails.",
        "Zu den bemerkenswerten Funktionen gehören ein robustes Nachrichtensystem, Benutzerbewertungen und Ratings, kategoriebasierte Anzeigenorganisation, erweiterte Such- und Filtermöglichkeiten sowie eine umfassende Benutzerprofilverwaltung. Die Anwendung folgt Best Practices sowohl in der Frontend- als auch in der Backend-Entwicklung, mit gründlichem Testing und Dokumentation.",
      ],
      inventory: [
        "Ein Python-basiertes Inventarverwaltungssystem, das den Prozess der Verwaltung von Inventargegenständen über eine Befehlszeilenschnittstelle vereinfacht.",
        "Entwickelt mit Python und integriert mit der Google Sheets API zur Datenspeicherung, bietet diese Anwendung umfassende Inventarverwaltungsfunktionen einschließlich Hinzufügen, Aktualisieren, Löschen und Suchen von Artikeln.",
        "Zu den Funktionen gehören eine robuste Eingabevalidierung, detaillierte Hilfedokumentation und eine benutzerfreundliche Oberfläche, die mit der Rich-Bibliothek für eine bessere Terminal-Ausgabeformatierung erweitert wurde.",
        "Das System implementiert CRUD-Operationen mit Google Sheets-Integration, angemessene Fehlerbehandlung und umfassende Tests zur Gewährleistung von Zuverlässigkeit und Datenintegrität.",
      ],
      watchStore: [
        "Watch Store ist eine umfassende E-Commerce-Anwendung, die ich mit React für die Frontend-Entwicklung und Node.js für die Backend-Entwicklung entwickelt habe. Die Benutzeroberfläche der Anwendung wurde mit Tailwind CSS gestaltet und das Datenbankverwaltungssystem wird von MySQL angetrieben.",
      ],
      coldAvira: [
        "Cold Avira ist eine Single-Page-Application Landingpage, die ich mit ReactJS für das Frontend-Framework, Tailwind CSS für die Gestaltung und SCSS für zusätzliche Styling-Funktionalitäten entwickelt habe.",
        "Die Designinspiration und einige der verwendeten Bilder in diesem Projekt stammen von templatemaster.com",
      ],
      foodOnline: [
        "Food Online ist eine Webanwendung, die mit Next Js und TypeScript entwickelt wurde. Das Front-End ermöglicht eine einfache Online-Bestellung, während das Back-End die Kontrolle über den Bestellstatus, die Lieferung und vieles mehr ermöglicht.",
      ],
      food: [
        "Master Cook ist eine Web-Anwendung, die ich mit ReactJs für das Frontend und ExpressJs für das Backend entwickelt habe. Die Benutzeroberfläche ist so gestaltet, dass es einfach ist, Online-Bestellungen aufzugeben, während das Backend leistungsfähige Funktionalität zur Verwaltung von Bestellstatus, Lieferung und anderen wichtigen Funktionen bietet.",
      ],
      snippets: [
        "Snippets ist eine Web-Anwendung, die ich entwickelt habe, um Code-Snippets zu speichern und zu teilen.",
        "Dieses Projekt befindet sich in kontinuierlicher Entwicklung. Neue Funktionen und Änderungen werden fast täglich vorgenommen. Der kleine Bildschirmstil kann aufgrund der Art dieser Anwendung vollständig entfernt werden.",
        'Ich habe ein großes Ehrgeiz, diese Anwendung zu entwickeln und auf ein höheres Niveau zu bringen. Neue Funktionen wie ein Abschnitt "Fehler Tracking" nach aktuellen Trends und Integration in ChatGPT werden in Kürze stattfinden.',
        "Sie wurde mit ReactJs für das Frontend, Tailwind CSS für die Gestaltung, ExpressJs für das Backend und MySQL für die Datenbankverwaltung erstellt.",
      ],
      movie: [
        "Diese Web-Anwendung, die auf der MovieDB-API basiert, wurde mit VueJS erstellt und ermöglicht es den Benutzern, die MovieDB-Datenbank einfach nach Filminformationen zu durchsuchen.",
        "Neben der Verwendung von Vue und seinem leistungsstarken State Management, habe ich in dieser Web-Anwendung auch Tailwind CSS für die Gestaltung verwendet.",
      ],
      utility: [
        "Dieses Projekt dient als Demonstration meiner Fähigkeiten in JavaScript und meiner Fähigkeit, das Document Object Model (DOM) zu manipulieren.",
        "Als kurze Anmerkung möchte ich auf meine Fähigkeiten in der smarten lokalen Speicherung, Datenformatierung und Validierung mit Vanilla JavaScript hinweisen. Für weitere Informationen, konsultieren Sie bitte den Informationsbereich des Live-Projekts.",
      ],
      design: [
        "Dieses Projekt verfügt über ein sauberes und poliertes Design, das leicht in eine erfolgreiche Website umgewandelt werden kann.",
        "Dieses Projekt hat einen mobile-first Designansatz und nutzt HTML und CSS, um eine optimale Ansicht auf mobilen Geräten zu gewährleisten.",
      ],
      bunbleClone: ["Ein Landing Page Klon von einem der coolsten codecanyon.net Projekte.", "Mobile-First-Design mit HTML und CSS."],
      github: "Weitere Projekte und meine Leidenschaft für Programmierung finden Sie auf meinem GitHub-Profil zur weiteren Referenz.",
    },
  },
  foodOnline: {
    description: ["Hauptmerkmale"],
    frontEnd: [
      "Kunden können sich registrieren und einloggen, um eine bequeme Bestellung zu ermöglichen.",
      "Bestellungen können auch ohne ein Konto aufgegeben werden.",
      "Registrierte Benutzer können ihre Kontodetails aktualisieren, Bestellstatus und -verlauf einsehen.",
    ],
    backEnd: [
      "Anwendungsadministratoren können einfach Produkte, Bilder und Kategorien erstellen und aktualisieren.",
      "Anwendungsadministratoren können neue Benutzer hinzufügen und deren Rolle als Kunde, Administrator oder Kurier definieren.",
      "Bestellungen werden bearbeitet und an die Konto der Kuriere zur Lieferung gesendet.",
      "Bestellstatus können geändert und verfolgt werden.",
      "Administratoren können alle Kurierbestellungen nach Status, Bestelldetails und Barumsatz ansehen.",
      "Es ist auch ein Tag-Filter verfügbar sowie ein tägliches Gesamteinkommen.",
    ],
    courier: [
      "Der Kurierbereich ist für mobile Geräte entwickelt und ermöglicht es Kurieren, Bestellungen, die zur Lieferung zugewiesen wurden, gelieferte Bestellungen und das Gesamteinkommen zu verfolgen.",
      "Kuriere können auch ihre ausgelieferten Aufträge überprüfen.",
      'Jeder Bestellabschnitt hat eine Funktion "Zur Adresse gehen", die automatisch Google Maps mit der vorausgefüllten Adresse des Kunden öffnet.',
    ],
  },
  masterCook: {
    description: ["Wesentliche Merkmale"],
    frontEnd: [
      "Kunden können sich registrieren und einloggen, um einfach zu bestellen.",
      "Bestellungen können auch ohne ein Konto aufgegeben werden.",
      "Registrierte Benutzer können ihre Kontodaten aktualisieren und den Bestellstatus und verlauf einsehen.",
    ],
    backEnd: [
      "Anwendungsadministratoren können Produkte, Bilder und Kategorien einfach erstellen und aktualisieren.",
      "Administratoren können neue Benutzer registrieren und ihre Rolle als Kunde, Administrator oder Kurier bestimmen.",
      "Bestellungen werden verarbeitet und zur Auslieferung an die Konten der Kuriere gesendet.",
      "Der Auftragsstatus kann geändert und nachverfolgt werden.",
      "Administratoren können jeden Kurierauftrag nach Status, Auftragsdetails und Bareinnahmen überprüfen.",
      "Ein Tagesfilter ist verfügbar sowie ein tägliches Gesamteinkommen.",
    ],
    courier: [
      'Der Bereich "Kuriere" ist für mobile Geräte konzipiert und verfolgt die zur Auslieferung zugewiesenen Aufträge, die ausgelieferten Aufträge und die Gesamteinnahmen.',
      "Kuriere können auch ihre ausgelieferten Aufträge überprüfen.",
      'Jeder Auftragsabschnitt hat eine "Go to address"-Funktion, die automatisch Google Maps mit der vorausgefüllten Kundenadresse öffnet.',
    ],
    demo: ["Demo-Konten: ", "Administrator", "Benutzer", "Kennwort", "Kurier", "Kunde"],
  },
  watchStore: {
    description: ["Anwendungsmerkmale:"],
    customersDescription: ["Kunden:"],
    customers: [
      "können sich registrieren und eine neue Bestellung aufgeben",
      "den Bestellstatus verfolgen",
      "Persönliche Informationen und Zugangsdaten aktualisieren (E-Mail- und Passwortaktualisierung)",
    ],
    administratorDescription: ["Administrator:"],
    administrator: [
      "Persönliche Informationen und Zugangsdaten aktualisieren (E-Mail- und Passwortaktualisierung)",
      "neue Bestellungen prüfen und aktualisieren, abgeschlossene Bestellungen prüfen",
      "neue Produkte erstellen, aktualisieren und löschen",
    ],
  },
  contact: {
    title: "Kontact",
    intro:
      "Ich interessiere mich sowohl für Freelance-Möglichkeiten als auch für Vollzeitpositionen, insbesondere für Rollen, die mich herausfordern zu lernen und zu wachsen. Wenn Sie ein Projekt haben, das Programmierkenntnisse benötigt, oder eine Stellenmöglichkeit, die zu meinen Fähigkeiten passt, würde ich mich freuen, von Ihnen zu hören.",
    subtitle: "Kontakt aufnehmen",
    description: "",
    address: "26655 Westerstede, Deutschland",
    phone: "0151 7164 1545",
  },
  contactForm: {
    connectWith: "Verbinden",
    or: "Oder",
    name: "Vollständiger Name",
    email: "E-Mail-Adresse",
    message: "Wie kann ich Ihnen helfen?",
    submit: "Nachricht senden",
    soon: "Vielen Dank für Ihre Nachricht! Ich werde mich innerhalb von 24 Stunden bei Ihnen melden.",
    sending: "Wird gesendet...",
    errors: {
      validation: "Bitte stellen Sie sicher, dass alle Felder korrekt ausgefüllt sind.",
      request: "Ihre Nachricht konnte nicht gesendet werden. Bitte versuchen Sie es später erneut.",
      network: "Nachricht konnte nicht gesendet werden. Bitte überprüfen Sie Ihre Internetverbindung.",
      configuration:
        "Es gibt ein Problem mit der Kontaktformular-Konfiguration. Bitte versuchen Sie es später erneut oder kontaktieren Sie mich direkt per E-Mail.",
      authentication:
        "Nachricht konnte aufgrund der Sicherheitsüberprüfung nicht gesendet werden. Bitte versuchen Sie es später erneut oder kontaktieren Sie mich direkt per E-Mail.",
    },
  },
};

export default translationDe;
