import React, { useState, Suspense, lazy, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Navbar from "./layout/Navbar";
import BackToTop from "./components/BackToTop";

// Lazy load sections
const About = lazy(() => import("./sections/About"));
const Projects = lazy(() => import("./sections/Projects"));
const Contact = lazy(() => import("./sections/Contact"));

const App = () => {
  const [activeSection, setActiveSection] = useState("about");

  useEffect(() => {
    window.history.replaceState({ type: "section", section: "about" }, "");

    // Reset scroll position on initial load
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });

    const handlePopState = (event) => {
      if (event.state?.type === "section") {
        setActiveSection(event.state.section);
        document.documentElement.scrollTo({
          top: 0,
          left: 0,
          behavior: "instant",
        });
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  // Create a wrapper for setActiveSection that also resets scroll
  const handleSectionChange = (section) => {
    window.history.pushState({ type: "section", section }, "");

    // Reset scroll position immediately without animation
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Use "instant" instead of "smooth" for immediate scroll
    });

    setActiveSection(section);
  };

  return (
    <div className="min-h-screen text-white bg-gray-900">
      <Navbar activeSection={activeSection} setActiveSection={handleSectionChange} />
      <main className="container px-4 py-8 mx-auto">
        <Suspense
          fallback={
            <div className="flex items-center justify-center w-full h-64">
              <div className="w-8 h-8 border-4 border-blue-500 rounded-full animate-spin border-t-transparent"></div>
            </div>
          }
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={activeSection}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="p-6 bg-gray-800 border rounded-lg border-blue-500/30"
            >
              {activeSection === "about" && <About setActiveSection={handleSectionChange} />}
              {activeSection === "projects" && <Projects setActiveSection={handleSectionChange} />}
              {activeSection === "contact" && <Contact setActiveSection={handleSectionChange} />}
            </motion.div>
          </AnimatePresence>
        </Suspense>
      </main>
      <BackToTop />
    </div>
  );
};

export default App;
